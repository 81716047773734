<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb"><a href="#">제품</a></li>
          <li class="breadcrumb" v-html="getHead" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb" v-html="getTitle" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb">관련 논문</li>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="section">
      <card-and-list
          title="관련 논문"
          description="BMS 제품 관련 주요 논문 내역과 관련 링크를 제공합니다."
          @getMoreList="getMoreList"
      />
    </div>
  </div>
</template>

<script>
import CardAndList from "@/components/common/CardAndList";
import {mapActions} from "vuex";

export default {
  name: "ClinicalTrialList",
  components: {CardAndList},
  props: {},
  computed: {
    getLandingPath() {
      return {
        name: 'Product',
        params: {productName: this.$route.params.productName},
      }
    },
    getHead() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
  },
  async mounted() {
    await this.initBoardList({
      query: {
        product: this.$route.params.productName,
      },
      type: 'clinical-trial'
    })
  },
  methods: {
    ...mapActions(["fetchBoardList", "initBoardList"]),
    async getMoreList() {
      await this.fetchBoardList({
        query: {
          page: this.$store.state.board.page + 1,
          product: this.$route.params.productName,
        },
        type: 'clinical-trial'
      })
    }
  },
}
</script>

<style scoped>

</style>
